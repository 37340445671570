<template>
  <!-- <validation-observer ref="saveTemplateModelForm" #default="{ invalid }"> -->
  <b-form id="saveTemplateModelForm" @submit.prevent="saveTemplateModel">
    <b-card>
      <b-container>
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="flat-warning"
              class="btn-icon"
              to="/configuration/template-models"
              v-b-tooltip.hover
              title="Go Back"
            >
              <feather-icon icon="ArrowLeftCircleIcon" size="20" />
            </b-button>
          </b-col>
          <b-col cols="12" md="10">
            <h1>Campaign Model Generator</h1>
          </b-col>
        </b-row>
        <hr />
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Campaign Model Title: *" label-for="h-title">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required|regex:^[a-zA-Z].*$"
              >
                <b-form-input
                  id="h-title"
                  v-model="title"
                  placeholder="Title"
                  :state="errors.length > 0 ? false : null"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Campaign Model Image: *" label-for="tempImage">
              <validation-provider
                #default="{ errors }"
                name="Image"
                rules="required"
              >
                <b-form-file
                  id="tempImage"
                  @change="tempImageInputChanged"
                  accept="image/*"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Choose an Image or drop it here..."
                  drop-placeholder="Drop here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <imgmng @onInsertImage="insertInsideTemplate" />
        <b-row class="justify-content-md-center">
          <b-col cols="12">
            <b-form-group label="Template:" label-for="did">
              <editor
                api-key="puknvskl3ay0k40d6ltd1mq8fueses5pvh2xoktlmtssny8q"
                v-model="templateContent"
                :init="{
                  selector: '.editor',
                  plugins:
                    'fullpage code advlist link image preview lists wordcount',
                  bbcode_dialect: 'punbb',
                  height: 600,
                }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <!-- <b-col offset-md="4"> -->
            <b-button
              type="submit"
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="outline-success"
              class="mr-1"
            >
              <!-- :disabled="invalid" -->
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-form>
  <!-- </validation-observer> -->
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BForm,
  BAvatar,
  BFormInput,
  BFormFile,
  VBTooltip,
  BPagination,
  BButton,
  BContainer,
} from "bootstrap-vue";

import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import Editor from "@tinymce/tinymce-vue";
import imagesManagement from "@/views/ads-manager/media-management/ImagesManagement.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormFile,
    VBTooltip,
    BAvatar,
    BForm,
    BFormInput,
    BPagination,
    BButton,
    BContainer,
    editor: Editor,
    imgmng: imagesManagement,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      title: null,
      imagePreview: null,
      templateContent: null,
      // validation rules
      required,
      regex,
      url,
    };
  },
  created() {},
  methods: {
    insertInsideTemplate(img) {
      const bodyTag = "</body>";
      let thereIsBody = this.templateContent.search(bodyTag);
      if (thereIsBody > 0) {
        let imgAdded = this.templateContent.replace("</body>", img + "</body>");
        this.templateContent = imgAdded;
        return;
      }
      this.templateContent += img;
    },
    logIt() {
      this.templateContent +=
        "<p>It also has some limited photo-editing capabilities, so you can make adjustments as needed right from your dashboard.</p>";
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    tempImageInputChanged(e) {
      this.imagePreview = e.target.files[0];
    },
    async saveTemplateModel() {
      let imageData = new FormData();

      imageData.append("title", this.title);
      imageData.append("image_preview", this.imagePreview);
      imageData.append("content_body", this.templateContent);
      await axios
        .post("/store-template-model", imageData)
        .then(({ data }) => {
          
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            // this.$refs.resetButton.click();
            this.did = null;
            router.push({ name: "configuration-template-models" });
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
};
</script>
